<template>
<v-layout column>
    <v-flex>
        <h2>
            <v-icon class="mr-2">mdi-dolly</v-icon>Products To Order
        </h2>
    </v-flex>
    <v-container>
        <v-flex>
            <v-container>
                <v-layout v-if="items.length>0" column>
                    <v-flex text-center>
                        <h2 class="report-heading">Order products</h2>
                    </v-flex>
                     <v-flex text-right>
                        <export-to-csv :data="items" :fileName="`products-to-order`"></export-to-csv>
                        <export-to-xls :data="items" :fileName="`products-to-order`"></export-to-xls>
                    </v-flex>
                    <v-simple-table>
                    <thead>
                        <tr>
                            <th>Code</th>
                            <th>Title</th>
                            <th class="text-right">Stock</th>
                            <th class="text-right">Reserve Stock</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in items" :key="item.code">
                            <td>{{item.code}}</td>
                            <td>{{item.title}}</td>
                            <td class="text-right">{{item.stock}}</td>
                            <td class="text-right">{{item.reserveStock}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        
                    </tfoot>
                </v-simple-table>
                </v-layout>
                <v-flex text-center>
                    <progress-bar :show="loading"></progress-bar>
                </v-flex>
            </v-container>
        </v-flex>
    </v-container>
</v-layout>
</template>

<script>
import appConstants from '@/utils/appConstants'
import ExportToCsv from '@/components/ExportToCsv'
import ExportToXls from '@/components/ExportToXls'
export default {
    components: {
        ExportToCsv,
        ExportToXls
    },
    data() {
        return {
            items: [],
            loading: false,
            downloadType: 'csv',
        }
    },
    mounted () {
        this.generateReport();
    },
    methods: {
        async generateReport() {
            try {
                this.loading = true
                this.items = await this.getItem(appConstants.PRODUCTS_API+"/?productToOrder=true")
            } catch (error) {
                console.error(error)
            } finally {
                this.loading = false
            }
        }
    },
}
</script>

<style scoped>
.report-heading{
    font-size: 1.2rem !important;
    font-weight: 500
}
.border-top{
    border-top: solid 1px black;
}
.big-heading{
    font-size: 1.3rem;
    font-weight: 500;
}
</style>
